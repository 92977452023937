* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body, html {
  background-color: #1c1c1c;
  height: 100%;
  margin: 0;
  font-family: Arial, sans-serif;
  overflow: hidden;
}

.container {
  flex-direction: column;
  height: 100dvh;
  display: flex;
  overflow: hidden;
}

.header-content {
  z-index: 10;
  background-color: #0000;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 15dvh;
  padding: 1em;
  display: flex;
  position: relative;
}

.logo {
  object-fit: contain;
  cursor: pointer;
  width: 18vw;
  height: auto;
  margin: 10px 0;
  position: absolute;
  left: 20px;
}

.footer {
  color: beige;
  text-align: center;
  z-index: 10;
  background-color: #1c1c1c;
  flex-shrink: 0;
  width: 100%;
  padding: 1em;
  font-size: clamp(10px, 2.5vw, 14px);
}

.footer a {
  color: beige;
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}

.footer p {
  margin: 0;
}

h1 {
  color: beige;
  white-space: nowrap;
  text-align: center;
  margin: 0;
  font-size: 6vw;
}

@media (width >= 481px) {
  .logo {
    width: 14vw;
  }

  h1 {
    font-size: 5vw;
  }
}

@media (width >= 769px) {
  .logo {
    width: 10vw;
  }

  h1 {
    font-size: 4vw;
  }

  .header-content {
    padding: 20px 0;
  }
}
/*# sourceMappingURL=index.c80ac758.css.map */
