/* Reset */
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

/* General body styling */
body, html {
    height: 100%;
    margin: 0;
    font-family: Arial, sans-serif;
    background-color: #1C1C1C;
    overflow: hidden;
}

/* Main container */
.container {
    display: flex;
    flex-direction: column;
    height: 100dvh;
    overflow: hidden;
}

/* Header styling */
.header-content {
    position: relative;
    height: 15dvh;
    width: 100%;
    padding: 1em;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
}

/* Logo styling */
.logo {
    position: absolute;
    left: 20px;
    width: 18vw;
    height: auto;
    object-fit: contain;
    margin: 10px 0;
    cursor: pointer;
}

.footer {
    background-color: #1C1C1C;
    color: #F5F5DC;
    text-align: center;
    padding: 1em;
    font-size: clamp(10px, 2.5vw, 14px);
    width: 100%;
    flex-shrink: 0;
    z-index: 10;
}

.footer a {
    color: #F5F5DC;
    text-decoration: none;
}

.footer a:hover {
    text-decoration: underline;
}

.footer p {
    margin: 0;
}

/* HIIT OF THE DAY text styling */
h1 {
    color: #F5F5DC;
    font-size: 6vw;
    white-space: nowrap;
    margin: 0;
    text-align: center;
}

/* Media Queries */
@media (min-width: 481px) {
    .logo {
        width: 14vw;
    }

    h1 {
        font-size: 5vw;
    }
}

@media (min-width: 769px) {
    .logo {
        width: 10vw;
    }

    h1 {
        font-size: 4vw;
    }

    .header-content {
        padding: 20px 0;
    }
}
